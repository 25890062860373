import {Formik, Form} from 'formik'
import {trackPageOverviewSchema} from '../../core/yup/track-page'
import {useEffect, useState} from 'react'
import {CardFooter} from '../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {ITrackPageOverviewTab} from '../../core/_models/_track-page-models'
import {CheckboxSwitcher} from '../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {Track} from '../../core/_models/_tracks-models'
import {Notification} from '../../../../modules/custom/Notification'
import {TrackCodeField} from '../../modules/general/TrackCodeField'
import {editTrack} from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {ITariffs} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {shapeFinalContainObjForBackend} from '../../../../../_metronic/helpers/custom/manageOrderContainProp'
import ContainCheckboxes from '../../../../modules/custom/sections/orders/ContainCheckboxes'

interface Props {
  track: Track
  setCurrTrack: (obj: Track) => void
  isArchive: boolean
}

export function TrackOverview({track, setCurrTrack, isArchive}: Props) {
  const intl = useIntl()
  const {
    track_code,
    type_delive,
    is_large,
    description,
    return_to_shop,
    track_package,
    in_storage,
  } = track
  const queryClient = useQueryClient()
  const tariffsData: ITariffs | undefined = queryClient.getQueryData('tariffsList')
  const initialValues = {
    track_code,
    deliveryType: type_delive,
    description,
    returnToStore: return_to_shop === 1 ? true : false,
    contain: track.contain
      ? Object.entries(track.contain)
          .filter(([_, value]) => value)
          .map(([key, _]) => key)
      : [],
  }
  const [trackCode, setTrackCode] = useState(track_code)
  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTrackCode(track_code)
  }, [track_code])

  const handleSubmit = async (values: ITrackPageOverviewTab) => {
    const {track_code, deliveryType, description, returnToStore} = values
    setLoading(true)
    const toSend = {
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      track_code,
      type_delive: deliveryType,
      description,
      return_to_shop: returnToStore === true ? 1 : 0,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,
    }
    if (tariffsData?.optional_fields?.contain) {
      toSend.contain = shapeFinalContainObjForBackend(
        tariffsData.optional_fields.contain,
        values.contain
      )
    }
    const response = await editTrack(toSend)

    if (response) {
      setCurrTrack(response)
      queryClient.invalidateQueries(QUERIES.BALANCE_CURRENT)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      {isArchive === true ? (
                        values.track_code
                      ) : (
                        <TrackCodeField
                          disabled={in_storage === 1 || track_package.length > 0 ? true : false}
                          withTitle={false}
                          setFieldValue={setFieldValue}
                          value={values.track_code}
                          initialValue={track_code}
                          trackCodeStatus={
                            trackCodeStatus.msg === 'TRACKS.TRACK_CODE.ERROR.ALREADY_ADDED'
                              ? {success: true, msg: '', id: ''}
                              : trackCodeStatus
                          }
                          setTrackCodeStatus={setTrackCodeStatus}
                          trackCode={trackCode}
                          setTrackCode={setTrackCode}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACK_PAGE.GEN_TAB.DELIVERY_TYPE' />
                </label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <CheckboxSwitcher
                        inputName='deliveryType'
                        firstOption={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA'})}
                        secondOption={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.SEA'})}
                        disabled={
                          isArchive === true ? true : track_package.length > 0 ? true : false
                        }
                        // @ts-ignore
                        value={+values.deliveryType}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {is_large === 1 && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>

                  <div className='col-lg-8'>
                    <Notification
                      noteText={intl.formatMessage({id: 'TRACK_PAGE.GEN_TAB.VOLUM_TRACK_PROMPT'})}
                      link={{
                        url: tariffsData?.tariffs_large_url ? tariffsData?.tariffs_large_url : '',
                        text: intl.formatMessage({
                          id: 'TRACK_PAGE.GEN_TAB.VOLUM_TRACK_PROMPT.LINK_WORD',
                        }),
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACK_PAGE.GEN_TAB.COMMENT' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    {isArchive === true || track_package.length > 0 ? (
                      values.description || '-'
                    ) : (
                      <InputTemplate type='textarea' inputName='description' />
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACK_PAGE.GEN_TAB.BACK_TO_STORE' />
                </label>
                <div className='col-lg-8'>
                  <div className='row d-flex flex-row'>
                    <div className='col-lg-12 fv-row d-md-flex'>
                      <div className='d-flex flex-column mb-5 mb-md- me-0 me-md-5'>
                        <InputTemplate
                          inputName='returnToStore'
                          type='checkbox'
                          containerMarginBottom={false}
                          checkboxProps={{
                            checked: values.returnToStore,
                            disabled:
                              isArchive === true ? true : track_package.length > 0 ? true : false,
                            setFieldValue: setFieldValue,
                            value: 1,
                            inputName: 'returnToStore',
                            type: 'checkbox',
                          }}
                        />
                        <p
                          className='text-muted fs-7 m-0'
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: 'TRACK_PAGE.GEN_TAB.BACK_TO_STORE.COST',
                            }),
                          }}
                        ></p>
                      </div>
                      {track_package.length > 0 && (
                        <Notification
                          noteText={intl.formatMessage({
                            id: 'TRACK_PAGE.GEN_TAB.NO_BACK_TO_STORE_PROMPT',
                          })}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {values.returnToStore === true && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <Notification
                        noteText={intl.formatMessage({
                          id: 'TRACK_PAGE.GEN_TAB.BACK_TO_STORE_NOTIFICATION',
                        })}
                      />
                    </div>
                  </div>
                </div>
              )}
              {tariffsData?.optional_fields?.contain && (
                <div className='row mb-5 align-items-start'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 p-0'>
                    <FormattedMessage id='ORDERS.CONTAIN.LABEL' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <ContainCheckboxes
                        tariffsContain={tariffsData?.optional_fields?.contain}
                        valuesContain={values.contain}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isArchive === false && <CardFooter loading={loading} success={success} />}{' '}
          </Form>
        )}
      </Formik>
    </div>
  )
}
