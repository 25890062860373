import {IBuyoutFormProds} from '../../../../app/pages/buyouts/vector/core/_models/_buyout-models'
import {ITrackFormProds} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const checkForAnyCommercialProducts = (
  prods: ITrackFormProds[] | IBuyoutFormProds[],
  delType: number
) => {
  //  brand
  const isCommercialBrand = prods?.some(({brand}) => brand?.commercial && +brand?.commercial === 1)

  // qty

  const isAnyCommQty = prods?.some(
    ({name, qty}) =>
      name?.settings &&
      +name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
      +qty >= +name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty
  )

  const isCommercialQty = isAnyCommQty || isAnyTotalQuantityExceeded(prods, delType)

  // price
  const isCommercialPrice = prods?.some(
    ({name, cost}) =>
      name?.settings &&
      +name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price &&
      +cost >= +name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price
  )

  let prodsAboveCap = false

  if (delType === 1) {
    prodsAboveCap = anyProdsAboveCap(prods)
  }

  const anyComProds = isCommercialBrand || isCommercialQty || isCommercialPrice
  return prodsAboveCap === true ? false : anyComProds
}

export const checkProductForCommercialValues = (
  prod: ITrackFormProds | IBuyoutFormProds,
  delType: number
) => {
  const isComProd =
    (prod?.brand?.commercial && +prod?.brand?.commercial === 1) ||
    (prod?.name?.settings &&
      +prod?.name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price &&
      +prod?.cost >= +prod?.name?.settings[delType === 0 ? 'avia' : 'sea'].mitn_price) ||
    (prod?.name?.settings &&
      +prod?.name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
      +prod?.qty >= +prod?.name?.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty)

  return isComProd
}
export const checkProductInsurance = (
  prod: ITrackFormProds | IBuyoutFormProds,
  delType: number,
  hasReplica: boolean = false
) => {
  const isComProd = checkProductForCommercialValues(prod, delType)
    ? (hasReplica ? 'mitn_brand' : 'mitn')
    : (hasReplica ? 'norm_brand' : 'norm');

  return prod?.name?.insurance?+prod?.name?.insurance[isComProd]:1;
}

export const isAnyTotalQuantityExceeded = (
  prods: ITrackFormProds[] | IBuyoutFormProds[],
  delType: number
) => {
  const productQuantities: Record<string, {total: number; com: number}> = {}

  for (const prod of prods || []) {
    if (
      prod?.name?.settings &&
      +prod.name.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty &&
      prod?.name?.value
    ) {
      const productId = prod.name.value
      const comQty = +prod.name.settings[+delType === 0 ? 'avia' : 'sea'].mitn_qty
      const totalQty = (productQuantities[productId]?.total || 0) + +prod.qty

      productQuantities[productId] = {
        com: comQty,
        total: totalQty,
      }
    }
  }

  const result = productQuantities
    ? Object.entries(productQuantities).some(([key, {total, com}]) => total >= com)
    : false

  return result
}

export const anyProdsAboveCap = (
  prods: ITrackFormProds[] | IBuyoutFormProds[],
  delType: number = 1
) => {
  return prods.some((prod) =>
    prod.name.settings?.sea?.cap ? prod.cost > prod.name.settings?.sea?.cap : false
  )
}
