import {ISetFieldValue, ITariffs} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {AddTrackForm, ITrackFormProds} from '../../core/_models/_tracks-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {getTrackCategories} from '../../core/_requests'
import {CategoriesWrapper} from '../../../../modules/custom/sections/categories/CategoriesWrapper'
import {useCallback, useEffect, useMemo} from 'react'
import {
    anyProdsAboveCap,
    checkForAnyCommercialProducts, checkProductInsurance,
} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {useListView} from '../../core/ListViewProvider'
import {Notification} from "../../../../modules/custom/Notification";
import {useQueryClient} from "react-query";
import {establishCountryFromOwner} from "../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain";
import {Field} from "formik";

interface Props {
  item: ITrackFormProds
  index: number
  arrayHelpers: any
  prods: ITrackFormProds[]
  setFieldValue: ISetFieldValue
  values: AddTrackForm
}

export function TrackGoodsItemBody({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
}: Props) {
  const intl = useIntl()
  const {itemIdForUpdate} = useListView()
  // const isCommercialProduct = useMemo(() => checkForAnyCommercialProducts(prods), [prods])
  const isCommercialProduct = checkForAnyCommercialProducts(prods, values.delivery_type)
  const prodsAboveCap = anyProdsAboveCap(prods)

  const queryClient = useQueryClient()
  const tariffsListData: ITariffs | undefined = queryClient.getQueryData('tariffsList')
  const country = establishCountryFromOwner().intl

  const weightIsNeeded = useMemo(
    () => item.name.require_weight && +item.name.require_weight === 1,
    [item]
  )

  useEffect(() => {
    // if (isCommercialProduct === true) {
    //   setFieldValue('package_type', 1)
    //   setFieldValue('require_invoice', 1)
    // } else {
    //   if (!itemIdForUpdate) {
    //     setFieldValue('package_type', 0)
    //   }
    //   if (item.name.require_invoice === 1) {
    //     setFieldValue('require_invoice', 1)
    //   } else {
    //     setFieldValue('require_invoice', 0)
    //   }
    //   return
    // }

    if (
      (values.delivery_type === 1 && prodsAboveCap === true) ||
      (isCommercialProduct === false && !itemIdForUpdate)
    ) {
      setFieldValue('package_type', 0)
    } else if (isCommercialProduct === true) {
      setFieldValue('package_type', 1)
    }

    // console.log(
    //   'isCommercialProduct',
    //   isCommercialProduct,
    //   'item.name.require_invoice',
    //   item.name.require_invoice
    // )

    if (isCommercialProduct === true || item.name.require_invoice === 1) {
      setFieldValue('require_invoice', 1)
    } else {
      setFieldValue('require_invoice', 0)
    }
  }, [isCommercialProduct])

  const hasReplica = values.contain.some(element => element.includes("replica"));
  const shouldShowRepack = Boolean(item?.name?.repack) && Number(item.name.repack) === 1;
  const shouldShowUsed = Boolean(item?.name?.used) && Number(item.name.used) === 1;

  const insuranceType = useMemo(() =>
    checkProductInsurance(item, values.delivery_type, hasReplica)
  , [item, values.delivery_type, hasReplica]);

  useEffect(() => {
    setFieldValue(`prods.${index}.insuranceType`, insuranceType)
  }, [insuranceType])

  return (
    <>
      <div className='d-flex flex-column fv-row w-100'>
        <CategoriesWrapper
          inputName={{
            groups: `prods.${index}.group`,
            categories: `prods.${index}.name`,
            brands: `prods.${index}.brand`,
            user_name: `prods.${index}.user_name`,
            model: `prods.${index}.model`,
          }}
          setFieldValue={setFieldValue}
          selectValues={{groups: item.group, categories: item.name, brands: item.brand}}
          apiRequest={getTrackCategories}
          disabled={!!itemIdForUpdate}
          values={item}
          delType={+values.delivery_type === 0 ? 'avia' : 'sea'}
        />
      </div>
      <div className='d-flex'>
        {shouldShowRepack && (
          <InputTemplate
            inputName={`prods.${index}.repack`}
            type='checkbox'
            checkboxProps={{
              checked: item.repack,
              disabled: !!itemIdForUpdate,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: `prods.${index}.repack`,
              type: 'checkbox',
            }}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_BOXES'})}
          />
        )}
        {shouldShowUsed && (
          <InputTemplate
            inputName={`prods.${index}.used`}
            type='checkbox'
            checkboxProps={{
              checked: item.used,
              disabled: !!itemIdForUpdate,
              setFieldValue: setFieldValue,
              value: 1,
              inputName: `prods.${index}.used`,
              type: 'checkbox',
            }}
            title={intl.formatMessage({id: 'GENERAL.STATUSES.USED'})}
          />
        )}
      </div>
      <div className='d-flex flex-gutter w-100'>
        <InputTemplate
          inputName={`prods.${index}.qty`}
          fieldWidth='w-50'
          disabled={!!itemIdForUpdate}
          type='number'
          required={true}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
          value={item.qty}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.total`, (val * +item.cost).toFixed())
          }}
          numberWithControls={true}
        />

        <InputTemplate
          inputName={`prods.${index}.cost`}
          type='number'
          fieldWidth='w-50'
          required={true}
          disabled={!!itemIdForUpdate}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST'})}
          addSymbol={'$'}
          value={item.cost}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.total`, (val * +item.qty).toFixed())
          }}
        />
      </div>
      {' '}
      <div className='d-flex flex-gutter w-100 align-items-center'>
        {/* {weightIsNeeded === true && (
          <InputTemplate
            inputName={`prods.${index}.weight`}
            type='number'
            fieldWidth='w-50'
            disabled={!!itemIdForUpdate}
            title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT'})}
            addSymbol={'кг'}
            value={item.weight}
          />
        )}{' '} */}
        <InputTemplate
          inputName={`prods.${index}.total`}
          addSymbol={'$'}
          type='number'
          fieldWidth='w-50'
          required={true}
          disabled={!!itemIdForUpdate}
          title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST'})}
          value={item.total}
          handleChange={(val: number) => {
            setFieldValue(`prods.${index}.cost`, (val / +item.qty).toFixed(1))
          }}
        />
      </div>
      <div className='d-flex flex-gutter flex-column w-100 align-items-center'>
        {insuranceType === 0 ? (
          <div className='d-flex flex-gutter w-100'>
            <Notification
              noteText={
                <>
                  <h3 className='fs-6'>
                    <FormattedMessage id='TRACKS.ADD_MODAL.FORM.GOODS_ITEM.INS_FULL'/>
                  </h3>
                </>
              }
            />
          </div>
        ) : (
          <>
            {item.ins_sum <= 0 &&
                <div className='d-flex flex-gutter w-100 mb-5'>
                    <Notification
                        noteText={
                          <>
                            <h3 className='fs-6'>
                              <b>
                                {` $${tariffsListData?.free_ins}/${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}
                              </b>{' '}
                              {intl.formatMessage({id: `COMPONENTS.TrackTarrifs.NON_COMM.${country}.INSURANCE_L`})}
                            </h3>
                          </>
                        }
                    />
                </div>
            }
            <div className='d-flex flex-gutter w-100'>
              <InputTemplate
                inputName='insurance'
                type='checkbox'
                fieldWidth='w-50'
                checkboxProps={{
                  className: insuranceType === 2?"d-none":"",
                  checked: item.ins_sum > 0,
                  disabled: false,
                  setFieldValue: setFieldValue,
                  value: 1,
                  inputName: `prods.${index}.insurance`,
                  type: 'checkbox',
                  handleChange: (val: number) => {
                    setFieldValue(`prods.${index}.ins_sum`, item.ins_sum > 0 ? 0 : item.cost)
                  }
                }}
                title={intl.formatMessage(
                  {id: insuranceType === 1 ? `TRACKS.ADD_MODAL.FORM.INSURANCE_TITLE.${country}` : 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.INS_SUM_L'},
                  {perc: (hasReplica ? item?.name?.insurance?.perc_brand ?? '' : item?.name?.insurance?.perc ?? '') + '%'}
                )}
              />
              <InputTemplate
                inputName={`prods.${index}.ins_sum`}
                addSymbol={'$'}
                symbolMarginTop={false}
                disabled={insuranceType === 1}
                type='number'
                fieldWidth='w-50'
                required={true}
                value={item.ins_sum}
              />
            </div>
            {' '}
          </>
        )}
        {prods.length > 1 && !itemIdForUpdate && (
          <div className='d-flex justify-content-center w-50'>
            {' '}
            <button
              className='btn btn-sm btn-secondary d-flex align-items-center'
              type='button'
              onClick={() => arrayHelpers.remove(index)}
            >
              <FormattedMessage id='GENERAL.BUTTONS.DELETE'/>
            </button>
          </div>
        )}
      </div>
            {' '}
            {/* {pageCustomData?.trackFormSimplified === false && isCommercialProduct === true && (
        <div className='d-flex justify-content-center px-auto'>
          <Notification
            noteText={
              <>
                <h3 className='fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                </h3>
                <TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />
              </>
            }
          />
        </div>
      )} */}
    </>
  )
}
