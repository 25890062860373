import {
  GoodsCategoriesQueryResponse,
  IBuyoutCategorySelectOption,
  ICatBrandSelectOption,
  IGoodsCategoryItem,
  IGoodsGroupSelectOption,
  ITrackCategorySelectOption,
} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'

import {customStyles} from '../../form-elements/select/ReactSelectTemplate'
import {ErrorMessage} from 'formik'
import {Dispatch, SetStateAction, useCallback} from 'react'

interface Props {
  setFieldValue: ISetFieldValue
  value: IBuyoutCategorySelectOption | ITrackCategorySelectOption
  options: IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  onInputChange: (query: string, callback?: any) => void
  apiRequest: (cat: string, groupId: string | number) => Promise<GoodsCategoriesQueryResponse>
  selectValues: {
    groups: IGoodsGroupSelectOption
    categories: IBuyoutCategorySelectOption | ITrackCategorySelectOption
    brands: ICatBrandSelectOption
  }
  setCategoriesResults: Dispatch<
    SetStateAction<IBuyoutCategorySelectOption[] | ITrackCategorySelectOption[]>
  >
  setResultsLoading: Dispatch<SetStateAction<boolean>>
}

export function SearchCategorySelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage = 'Не найдено',
  disabled = false,
  options,
  onInputChange,
  apiRequest,
  selectValues,
  setCategoriesResults,
  setResultsLoading,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = useCallback(
    (inputValue: string, callback: any) => {
      if (!inputValue) {
        callback(options)
        return
      }

      onInputChange(inputValue, callback)
    },
    [onInputChange, options]
  )

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <div className={`fv-row d-flex w-100 mb-5 flex-column`}>
        <label
          className={`d-flex position-relative  fs-5 flex-column fw-bold mb-2 w-100`}
          htmlFor={inputName}
        >
          <div className='mb-2'>
            {' '}
            <span className={`fs-6 required`}>
              <FormattedMessage id='COMPONENTS.SearchCategorySelect.CATEGORY'/>
            </span>
          </div>
          <AsyncSelect
            formatOptionLabel={({value, label, descr, image}) => (
              <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
                <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
                  <img
                    alt=''
                    className='rounded-circle w-50px me-2'
                    src={image || '/media/icons/duotune/ecommerce/ecm002.svg'}
                  />
                </div>

                <div className='d-flex flex-column text-gray-800'>
                  <strong>{label}</strong>
                  {descr && <span className='fs-6 fw-normal'>{descr}</span>}
                </div>
              </div>
            )}
            styles={customStyles}
            loadOptions={loadOptions}
            defaultOptions={options}
            form={inputName}
            isClearable={value.value === '' ? false : true}
            isDisabled={disabled}
            components={{NoOptionsMessage}}
            loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
            placeholder={intl.formatMessage({
              id: 'COMPONENTS.SearchCategorySelect.PLACEHOLDER',
            })}
            isLoading={isLoading}
            value={value}
            onInputChange={(e) => {
              onInputChange(e)
              // console.log('e', e)
            }}
            onChange={handleOnChange}
          />
        </label>
        <div className='text-danger'>
          <ErrorMessage name={`${inputName}.value`}/>
        </div>
        {value?.settings?.extra ? <div id={`${inputName}Help`} className="form-text">
          <FormattedMessage id="COMPONENTS.SearchCategorySelect.EXTRA_HELP" values={{cost: value?.settings?.extra}} defaultMessage={"Extra fee ${cost} per item"} />
        </div>: ''}
      </div>
    </>
  )
}
