import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Track} from '../../core/_models/_tracks-models'
import {TrackHeaderShipment} from './TrackHeaderShipment'
import {TrackHeaderInfo} from './TrackHeaderInfo'
import {ImageLightbox} from '../../../../modules/custom/images/ImageLightbox'
import {useState} from 'react'
import {TrackHeaderNav} from './TrackHeaderNav'

interface Props {
  track: Track
  isArchive: boolean
}

const TrackHeader = ({track, isArchive}: Props) => {
  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const {preview_files, check_answer_files, track_code} = track

  const allImages =
    preview_files && check_answer_files ? [...preview_files, ...check_answer_files] : []

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-center justify-content-md-between overflow-hidden overflow-md-auto'>
          <div className='d-flex flex-row w-100'>
            <div className='me-7 mb-4 cursor-zoom-in'>
              <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                {allImages?.length > 0 ? (
                  <div className='me-7 mb-4 cursor-zoom-in'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                      <img
                        src={allImages[0]}
                        width='45'
                        height='45'
                        alt='track'
                        onClick={openLightbox}
                        style={{objectFit: 'contain'}}
                      />

                      <button
                        type='button'
                        className='btn btn-sm position-absolute'
                        style={{top: '75%', right: '10px'}}
                        onClick={openLightbox}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil012.svg'
                          className='svg-icon-1 svg-icon-gray-900 me-1 position-absolute'
                        />
                        <span className='position-absolute top-75 end-0 text-white fs-8'>
                          {allImages.length}
                        </span>
                      </button>
                      <ImageLightbox
                        viewerIsOpen={viewerIsOpen}
                        closeLightbox={closeLightbox}
                        currentImage={currentImage}
                        images={allImages.map((item, index) => {
                          return {src: item, title: `${track_code}-${index + 1}`}
                        })}
                      />
                    </div>
                  </div>
                ) : (
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm010.svg')}
                    alt='Track'
                  />
                )}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <TrackHeaderInfo track={track} isArchive={isArchive} />{' '}
              {/* {(description || admin_comment) && (
                <div className='d-none d-md-block  mw-md-450px'>
                  {' '}
                  <HeaderMessage
                    comment={{
                      author: intl.formatMessage({id: 'TRACK_PAGE.STATUSES.CLIENT'}),
                      messageText: description,
                    }}
                    answer={{
                      messageText: admin_comment,
                      files: admin_comment_files,

                      author: intl.formatMessage({id: 'COMPONENTS.HeaderMessage.AUTHOR'}),
                    }}
                  />
                </div>
              )} */}
            </div>
          </div>
          {/* {(description || admin_comment) && (
            <div className='d-md-none'>
              {' '}
              <HeaderMessage
                comment={{
                  author: intl.formatMessage({id: 'TRACK_PAGE.STATUSES.CLIENT'}),
                  messageText: description,
                }}
                answer={{
                  messageText: admin_comment,
                  files: admin_comment_files,
                  author: intl.formatMessage({id: 'COMPONENTS.HeaderMessage.AUTHOR'}),
                }}
              />
            </div>
          )} */}

          <TrackHeaderShipment track={track} />
        </div>
        <TrackHeaderNav track={track} isArchive={isArchive} />
      </div>
    </div>
  )
}

export {TrackHeader}
