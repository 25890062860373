import {Route, Routes, Outlet, Navigate, useLocation, useParams} from 'react-router-dom'
import {TracksListWrapper} from './tracks-list/TracksList'
import TrackPage from './track-page/TrackPage'
import {generalApi} from '../../../_metronic/helpers/custom/api/generalApi'
import {useQuery} from 'react-query'
import {getRecentInvoices} from './core/_requests'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {pathnameValue} from './core/_funcs'

const TracksPage = () => {
  const location = useLocation()
  const {pathname} = location
  const intl = useIntl()
  const params = useParams()
  const delType = params['*']?.split('/') || []
  // console.log('delType', pathnameValue(delType[delType?.length - 1], intl).trackPagePath)

useQuery('receiversList', generalApi.getReceiversList)

 useQuery('tariffsList', generalApi.getTariffs)
  // console.log('=-', location.pathname.split('/')[location.pathname.split('/').length - 1])

  const recentInvoicesData: any = useQuery('recentInvoices', getRecentInvoices)

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/:type'
          element={
            <>
              <TracksListWrapper />
            </>
          }
        />

        {pathnameValue(delType[delType?.length - 1], intl).creationIsAllowed === true && (
          <Route
            path='create'
            element={
              <Navigate
                to={`/tracks/list/${delType[delType?.length - 1]}`}
                state={{
                  create: true,
                  deliveryType: pathnameValue(delType[delType?.length - 1], intl).deliveryType,
                }}
              />
            }
          />
        )}

        <Route
          path={':type/*'}
          element={
            <TrackPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/tracks/list/${delType[delType?.length - 1]}`}
            // to={pathnameValue(pathname, intl).defaultLink}
            // state={{deliveryType: pathnameValue(pathname, intl).deliveryType}}
          />
        }
      />
    </Routes>
  )
}

export default TracksPage
