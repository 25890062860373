import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {establishOwnerFromDomain, getHostUrl} from '../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {useAuth} from '../../../../../modules/auth'
import TelegramLoginButton, {TelegramUser} from "telegram-login-button";
import {FormattedMessage, useIntl} from "react-intl";
import {getUser, telegramLogin} from "../../../../../modules/auth/core/_requests";
import {crauzerProfileApi} from "../../../_requests";

interface Props {
  registerProps?: {
    isOpen: boolean
    setIsOpen: (b: boolean) => void
  }
}
const TelegramAuthModal = ({registerProps}: Props) => {
  const intl = useIntl()
  const {websiteData} = useLayout()
  const {currentUser, saveAuth, setCurrentUser} = useAuth()

  const [error, setError] = useState<boolean|string>(false)

  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleClose = () =>
    registerProps ? registerProps.setIsOpen(false) : false

  const closeOverlay = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (currentUser?.tosConfirmedWebsites[establishOwnerFromDomain()] === true || registerProps) {
      if (e.currentTarget === e.target) {
        handleClose()
      }
    } else {
      return
    }
  }

  const try_token_auth = async () => {
    const {data: user} = await getUser()
    const crUser = await crauzerProfileApi.getCurrentUser()
    setCurrentUser({...user, ...crUser, justLoggedIn: true})
  }
  const handleTelegramResponse = async (user: TelegramUser) => {
    try {
      console.log(user)

      const res = await telegramLogin(user)
      saveAuth({token: res.data.token})
      try_token_auth()
    } catch (error: any) {
      console.log('telegram err', error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      const receivedErr = error.response.data?.message

      if (receivedErr && receivedErr === 'AUTH.TELEGRAM_PHONE_NUMBER_REQUIRED') {
        console.log('err token', error?.response?.data?.data?.telegram_check_token)
        errMsg = intl.formatMessage({
          id: receivedErr,
        })
      } else if (receivedErr) {
        errMsg = intl.formatMessage({
          id: receivedErr,
        })
      }

      setError(errMsg)
    }
  }

  return (
    <>
      {(registerProps?.isOpen) && (
        <>
          <div
            className={`modal fade show d-block`}
            id='kt_modal_telegram'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            onClick={closeOverlay}
          >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered modal-md'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4>&nbsp;</h4>
                  {/* begin::Close */}
                  {registerProps && (
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-users-modal-action='close'
                      onClick={() => handleClose()}
                      style={{cursor: 'pointer'}}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  )}
                  {/* end::Close */}
                </div>
                <div className='modal-body scroll-y mx-3 my-3 text-center'>
                  {error && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{error}</div>
                    </div>
                  )}
                  <p className='h5'><FormattedMessage id='AUTH.VALIDATION.PHONE_NUMBER_TAKEN_BY_TELEGRAM_CHAT_USER' /></p>
                  {(getHostUrl() === 'vector-usa.biz' ||
                      getHostUrl() === 'vectorlogistic.net' ||
                      getHostUrl() === 'localhost') &&
                    websiteData.telegramBotName && (
                      <div>
                        <TelegramLoginButton
                          botName={websiteData.telegramBotName}
                          dataOnauth={handleTelegramResponse}
                          className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 px-4 fs-7 text-grey'
                          buttonSize='medium'
                        />
                      </div>
                    )}
                </div>

                {/* end::Modal body */}
              </div>

              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {TelegramAuthModal}
