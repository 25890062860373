import {useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {
  anyProdsAboveCap,
  checkForAnyCommercialProducts,
} from '../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {establishCountryFromOwner} from '../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {ITariffs} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {SettingsRadio} from '../../../../modules/custom/form-elements/radios/SettingsRadio'
import {Notification} from '../../../../modules/custom/Notification'
import {useListView} from '../../core/ListViewProvider'
import {AddTrackForm} from '../../core/_models/_tracks-models'
import {TrackTarrifs} from '../TrackTarrifs'

interface Props {
  values: AddTrackForm
}

export function TrackParcelType({values}: Props) {
  const {setFieldValue} = useFormikContext()
  const {itemIdForUpdate} = useListView()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const tariffsData: ITariffs | undefined = queryClient.getQueryData('tariffsList')

  return (
    <div className='w-100'>
      {+values.delivery_type === 1 && anyProdsAboveCap(values.prods) && (
        <div className='fv-row mb-5'>
          <Notification noteText={intl.formatMessage({id: 'TRACKS.GENERAL.NON_COMM_CUSTOMS'})} />
        </div>
      )}
      <div className='fv-row mb-5 text-primary'>
        <SettingsRadio
          title={intl.formatMessage({
            id: `TRACKS.ADD_MODAL.FORM.COMMERCIAL_TITLE.${establishCountryFromOwner().intl}`,
          })}
          disabled={
            (values.delivery_type === 1 && anyProdsAboveCap(values.prods) === true) ||
            !!itemIdForUpdate ||
            checkForAnyCommercialProducts(values.prods, values.delivery_type)
          }
          description={<TrackTarrifs delivery_type={+values.delivery_type} parcel_type={1} />}
          inputName='package_type'
          formValues={values}
          value={1}
          setFieldValue={setFieldValue}
        />
        <SettingsRadio
          title={intl.formatMessage({
            id: `TRACKS.ADD_MODAL.FORM.NON_COMM_TITLE.${establishCountryFromOwner().intl}`,
          })}
          disabled={
            (values.delivery_type === 1 && anyProdsAboveCap(values.prods) === true) ||
            !!itemIdForUpdate ||
            checkForAnyCommercialProducts(values.prods, values.delivery_type)
          }
          description={<TrackTarrifs delivery_type={+values.delivery_type} parcel_type={0} />}
          inputName='package_type'
          formValues={values}
          value={0}
          setFieldValue={setFieldValue}
        />
      </div>
      <div className='fv-row'>
        <Notification
          noteText={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.TARIFFS_EXPL',
          })}
          link={{
            url: tariffsData?.tariffs_url ? tariffsData?.tariffs_url : '',
            text: intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.TARIFFS_EXPL.LINK_WORD',
            }),
          }}
        />
      </div>
    </div>
  )
}
