import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import BuyoutPage from './buyout-page/BuyoutPage'
import {BuyoutsListWrapper} from './buyouts-list/BuyoutsList'
import {useIntl} from 'react-intl'
import {generalApi} from '../../../../_metronic/helpers/custom/api/generalApi'
import {useQuery} from 'react-query'
import {usePageData} from '../../../../_metronic/layout/core'
import {useEffect} from 'react'

const BuyoutsPage = () => {
  const location = useLocation()
  const {pathname} = location
  const intl = useIntl()
  useQuery('tariffsList', generalApi.getTariffs)
  const {pageCustomData} = usePageData()
  useEffect(() => {
    console.log('pageCustomData', pageCustomData)
  }, [pageCustomData])

  const pathnameValue = pathname.includes('/archived-buyouts')
    ? {
        defaultLink: '/archived-buyouts/list',
        pageTitle: intl.formatMessage({id: 'BUYOUTS.PAGE.PAGE_HEADING_ARCH'}),
        buyoutPagePath: 'info/*',
        creationIsAllowed: false,
      }
    : {
        defaultLink: '/buyouts/list',
        pageTitle: intl.formatMessage({id: 'BUYOUTS.PAGE.PAGE_HEADING'}),
        buyoutPagePath: 'edit/*',
        creationIsAllowed: true,
      }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <BuyoutsListWrapper />
            </>
          }
        />

        {pathnameValue.creationIsAllowed === true && (
          <Route
            path='create'
            element={<Navigate to='/buyouts/list' state={location.pathname} />}
          />
        )}

        <Route
          path={pathnameValue.buyoutPagePath}
          element={
            <BuyoutPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to={pathnameValue.defaultLink} />} />
    </Routes>
  )
}

export default BuyoutsPage
