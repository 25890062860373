/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, useFormik, FormikProvider} from 'formik'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Modal} from 'react-bootstrap'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {createTrackPageContentCardSchema} from '../../../core/yup/track-page'
import {ITrackFormProds, ITrackProds, Track} from '../../../core/_models/_tracks-models'
import {
  reformOneTrackProduct,
  reformTrackProductsFromBackend,
} from '../../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {editTrack, getTrackCategories} from '../../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {CategoriesWrapper} from '../../../../../modules/custom/sections/categories/CategoriesWrapper'
import {TrackTarrifs} from '../../../modules/TrackTarrifs'
import {Notification} from '../../../../../modules/custom/Notification'
import {useAuth} from '../../../../../modules/auth'
import {
  anyProdsAboveCap,
  checkProductForCommercialValues, checkProductInsurance,
  isAnyTotalQuantityExceeded,
} from '../../../../../../_metronic/helpers/custom/funcs/checkForCommercial'
import {ICatBrandSelectOption} from '../../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {ITariffs} from "../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers";
import {establishCountryFromOwner} from "../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain";
import {checkoutSchema} from "../../../../../../_metronic/partials";

interface Props {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  dataForEditing: ITrackProds
  track: Track
  setCurrTrack: (obj: Track) => void
  readOnly: boolean
  categoryBrands: ICatBrandSelectOption[]
  setDisabledRadios: Dispatch<SetStateAction<boolean | undefined>>
}

const EditTrackContentCard = ({
  setIsOpen,
  isOpen,
  dataForEditing,
  track,
  setCurrTrack,
  readOnly,
  categoryBrands,
  setDisabledRadios,
}: Props) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {category, qty, cost, total, ins_sum, repack, id, group, wgt, brand, used, user_name, model} =
    dataForEditing
  const {data: categories} = useQuery('trackCategories', () => {
    return getTrackCategories('', '')
  })
  // console.log('dataForEditing', dataForEditing)

  const queryClient = useQueryClient()
  const tariffsListData: ITariffs | undefined = queryClient.getQueryData('tariffsList')
  const country = establishCountryFromOwner().intl

  const inits: ITrackFormProds = {
    group: {value: group?.id || '', label: group?.name || ''},
    name: {
      ...category,
      label: category?.name,
      value: category?.id,
      require_invoice: +category?.require_invoice,
      brands: categoryBrands,
    },
    brand: {
      ...brand,
      label: brand?.name || '',
      value: brand?.id ? +brand?.id : '',
    },
    qty,
    cost,
    total,
    ins_sum,
    repack: !!repack && +repack === 1 ? true : false,
    id: id || 0,
    weight: wgt || '',
    used: +used === 1 ? true : false,
    user_name: user_name || '',
    model: model || '',
  }

  // console.log('editinits', inits)

  const [isLoading, setIsLoading] = useState(false)

  const [initValues] = useState<ITrackFormProds>(inits)

  const editTrackContentMutation = useMutation(editTrack, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      if (data) {
        setCurrTrack(data)
      }
      closeModal()
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleSubmit = async (values: ITrackFormProds, actions: FormikValues) => {
    const isComProd = checkProductForCommercialValues(values, track.type_delive)

    const filteredWithoutEditedOne = track.prods.filter((item) => item.id !== id)

    const someOldCommercial = filteredWithoutEditedOne.some((item) => +item.commercial === 1)

    const reformedOldProds = await reformTrackProductsFromBackend(filteredWithoutEditedOne)

    const exceededQty = isAnyTotalQuantityExceeded([...reformedOldProds, values], track.type_delive)

    const prodsAboveCap =
      anyProdsAboveCap(reformedOldProds) ||
      (values.name?.settings?.sea.cap && values.cost > +values.name?.settings?.sea.cap)

    const mitn =
      +track?.type_delive === 1 && prodsAboveCap === true
        ? 0
        : isComProd === true || someOldCommercial === true || exceededQty === true
          ? 1
          : 0

    const dataToSend = {
      ...track,
      receiver: track?.receiver?.id ? +track?.receiver?.id : null,
      prods: [...track.prods.filter((item) => item.id !== id), {...reformOneTrackProduct(values)}],
      repack: values.repack === true ? 1 : 0,
      invoice_file:
        track.invoice_file.map((item) => {
          return {name: item.name, tmp_name: item.file.filename}
        }) || undefined,

      mitnitsya: mitn,
    }
    setDisabledRadios(prodsAboveCap === true ? true : mitn === 1)
    console.log('dataToSend', dataToSend)
    editTrackContentMutation.mutate(dataToSend)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: createTrackPageContentCardSchema(intl, +track.type_delive === 0 ? 'avia' : 'sea')[0],

    onSubmit: handleSubmit,
  })

  const hasReplica = track?.contain?.replica === true;

  const {values, setFieldValue, errors} = formik;

  const insuranceType = useMemo(() =>
      checkProductInsurance(values, track?.type_delive, hasReplica)
    , [values, track?.type_delive, hasReplica]);

  useEffect(() => {
    setFieldValue(`insuranceType`, insuranceType)
  }, [insuranceType])

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      id='kt_modal_edit_track_prod'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>
            <FormattedMessage
              id={
                readOnly === true
                  ? 'TRACK_PAGE.CONTENT_TAB.GOODS_MODAL.VIEW'
                  : 'TRACK_PAGE.CONTENT_TAB.GOODS_MODAL.EDIT'
              }
            />
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-5 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <FormikProvider value={formik}>
              <Form className='form' noValidate id='kt_modal_edit_track_prod_form'>
              {/*{JSON.stringify(errors)}*/}
              {/*{JSON.stringify(values)}*/}
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='d-flex flex-column fv-row w-100'>
                    <CategoriesWrapper
                      inputName={{
                        groups: `group`,
                        categories: `name`,
                        brands: 'brand',
                        user_name: `user_name`,
                        model: `model`,
                      }}
                      setFieldValue={setFieldValue}
                      selectValues={{
                        groups: values.group,
                        categories: values.name,
                        brands: values.brand,
                      }}
                      apiRequest={getTrackCategories}
                      disabled={readOnly}
                      values={values}
                      delType={+track.type_delive === 0 ? 'avia' : 'sea'}
                    />
                  </div>
                  <div className='d-flex'>
                    {values?.name?.repack && +values.name.repack === 1 && (
                      <InputTemplate
                        inputName={`repack`}
                        type='checkbox'
                        checkboxProps={{
                          checked: values.repack,
                          disabled: readOnly,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: `repack`,
                          type: 'checkbox',
                        }}
                        title={intl.formatMessage({
                          id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_BOXES',
                        })}
                      />
                    )}
                    {values?.name?.used && +values.name.used === 1 && (
                      <InputTemplate
                        inputName='used'
                        type='checkbox'
                        checkboxProps={{
                          checked: values.used,
                          disabled: readOnly,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'used',
                          type: 'checkbox',
                        }}
                        title={intl.formatMessage({id: 'GENERAL.STATUSES.USED'})}
                      />
                    )}
                  </div>
                  <div className='d-flex flex-gutter w-100'>
                    <InputTemplate
                      inputName={`qty`}
                      fieldWidth='w-50'
                      type='number'
                      disabled={readOnly}
                      required={true}
                      title={intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.QTY'})}
                      value={values.qty}
                      handleChange={(val: number) =>
                        setFieldValue(`total`, (val * +values.cost).toFixed())
                      }
                      numberWithControls={!readOnly}
                    />

                    <InputTemplate
                      inputName={`cost`}
                      type='number'
                      fieldWidth='w-50'
                      required={true}
                      disabled={readOnly}
                      title={intl.formatMessage({
                        id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST',
                      })}
                      addSymbol={'$'}
                      value={values.cost}
                      handleChange={(val: number) =>
                        setFieldValue(`total`, (val * +values.qty).toFixed())
                      }
                    />
                  </div>
                  {' '}
                  <div className='d-flex flex-gutter w-100'>
                    {/* {(values.name.require_weight && values.name.require_weight === 1) ||
                  (initValues.weight && initValues.weight > 0) ? (
                    <InputTemplate
                      inputName={`weight`}
                      type='number'
                      fieldWidth='w-50'
                      title={intl.formatMessage({
                        id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT',
                      })}
                      addSymbol={'кг'}
                      value={values.weight}
                    />
                  ) : (
                    <></>
                  )} */}
                    <InputTemplate
                      inputName={`total`}
                      addSymbol={'$'}
                      disabled={readOnly}
                      type='number'
                      fieldWidth='w-50'
                      required={true}
                      title={intl.formatMessage({
                        id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.TOTAL_COST',
                      })}
                      value={values.total}
                      handleChange={(val: number) =>
                        setFieldValue(`cost`, (val / +values.qty).toFixed(1))
                      }
                    />
                  </div>
                  {' '}
                  {/* {checkProductForCommercialValues(values) === true && (
                  <div className='d-flex justify-content-center px-auto'>
                    <Notification
                      noteText={
                        <>
                          <h3 className='fs-6'>
                            <FormattedMessage id='TRACKS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                          </h3>
                          <TrackTarrifs
                            delivery_type={
                              currentUser?.settings?.delivery_type
                                ? +currentUser?.settings?.delivery_type
                                : 0
                            }
                            parcel_type={1}
                          />
                        </>
                      }
                    />
                  </div>
                )} */}
                  {insuranceType === 0 ? (
                    <div className='d-flex flex-gutter w-100'>
                      <Notification
                        noteText={
                          <>
                            <h3 className='fs-6'>
                              <FormattedMessage id='TRACKS.ADD_MODAL.FORM.GOODS_ITEM.INS_FULL'/>
                            </h3>
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <>
                      {values.ins_sum <= 0 &&
                          <div className='d-flex flex-gutter w-100 mb-5'>
                              <Notification
                                  noteText={
                                    <>
                                      <h3 className='fs-6'>
                                        <b>
                                          {` $${tariffsListData?.free_ins}/${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}`}
                                        </b>{' '}
                                        {intl.formatMessage({id: `COMPONENTS.TrackTarrifs.NON_COMM.${country}.INSURANCE_L`})}
                                      </h3>
                                    </>
                                  }
                              />
                          </div>
                      }
                      <div className='d-flex flex-gutter w-100'>
                        <InputTemplate
                          inputName='insurance'
                          type='checkbox'
                          fieldWidth='w-50'
                          checkboxProps={{
                            className: insuranceType === 2?"d-none":"",
                            checked: values.ins_sum > 0,
                            disabled: readOnly,
                            setFieldValue: setFieldValue,
                            value: 1,
                            inputName: 'insurance',
                            type: 'checkbox',
                            handleChange: (val: number) => {
                              setFieldValue(`ins_sum`, values.ins_sum > 0 ? 0 : values.cost)
                            }
                          }}
                          title={intl.formatMessage(
                            {id: insuranceType === 1 ? `TRACKS.ADD_MODAL.FORM.INSURANCE_TITLE.${country}` : 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.INS_SUM_L'},
                            {perc: (hasReplica ? values?.name?.insurance?.perc_brand ?? '' : values?.name?.insurance?.perc ?? '') + '%'}
                          )}
                        />
                        <InputTemplate
                          inputName={`ins_sum`}
                          addSymbol={'$'}
                          symbolMarginTop={false}
                          disabled={readOnly || insuranceType === 1}
                          type='number'
                          fieldWidth='w-50'
                          required={true}
                          value={values.ins_sum}
                        />
                      </div>
                      {' '}
                    </>
                  )}
                </div>
              </div>
              {readOnly === false && (
                <div className='d-flex flex-stack justify-content-end pt-10'>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      disabled={isLoading}
                    >
                    <span className='indicator-label'>
                      <FormattedMessage id='GENERAL.BUTTONS.SAVE'/>
                    </span>
                    </button>
                  </div>
                </div>
              )}
            </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {EditTrackContentCard}
