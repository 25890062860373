import {useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {Notifications} from '../partials/notifications/Toasts'
import {CheckoutModalOptions} from '../partials/modals/balance/CheckoutModalOptions'
import {TermsOfServiceModal} from '../../app/pages/profile/vector/components/overview/TermsOfServiceModal'
import {useAuth} from '../../app/modules/auth'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const {currentUser} = useAuth()

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <Notifications />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div
                className='app-main flex-column justify-content-between flex-row-fluid'
                id='kt_app_main'
              >
                <div className='d-flex flex-column flex-column-fluid mb-5 mb-md-12'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <RightToolbar />
        {/* <ActivityDrawer />
        <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <InviteUsers />
        <UpgradePlan /> */}
        <CheckoutModalOptions />
        <TermsOfServiceModal />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
