import {useFormikContext} from 'formik'
import {ChangeEvent} from 'react'
import {useIntl} from 'react-intl'
import {ITariffsOptionalContain} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../form-elements/InputTemplate'

interface IProps {
  tariffsContain: ITariffsOptionalContain[]
  valuesContain: string[]
}
const ContainCheckboxes = ({tariffsContain, valuesContain}: IProps) => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<any>()
  const mapped = tariffsContain.map((i) => ({...i, name: i.name.split('[')[1].split(']')[0]}))
  console.log(mapped)

  return (
    <>
      {mapped.map((type) => (
        <InputTemplate
          key={type.name}
          type={type.type}
          inputName={`contain`}
          title={intl.formatMessage({
            id: `ORDERS.CONTAIN.${type?.name.toUpperCase()}`,
          })}
          required={false}
          checkboxProps={{
            multipleCheckbox: true,
            handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
              valuesContain.includes(value)
                ? setFieldValue(
                    'contain',
                    valuesContain.filter((i) => i !== value)
                  )
                : setFieldValue('contain', [...valuesContain, value])
            },
            checked: values.contain.includes(type.name),
            disabled: false,
            setFieldValue: setFieldValue,
            value: type.name,
            inputName: `contain`,
            type: 'checkbox',
          }}
        />
      ))}
    </>
  )
}

export default ContainCheckboxes
