import {ITariffsOptionalContain} from './tsHelpers/generalHelpers'

export const shapeFinalContainObjForBackend = (
  tariffsContain: ITariffsOptionalContain[],
  valuesContain: string[]
) => {
  let contain: Record<string, boolean> = {}
  tariffsContain.map((i) => (contain[i?.name?.split('[')[1].split(']')[0]] = false))
  valuesContain.forEach((element) => (contain[element] = true))

  return contain
}
